



























































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { vxm } from '@/store'
import { mask } from 'vue-the-mask'

@Component({
  directives: {
    mask,
  },
})
export default class AccountingRuleCreate extends Vue {
  $refs: Vue['$refs'] & {
    form: {
      validate: any
    }
  }

  pageMode = 'create'

  isSaving = false
  fallbackType = null

  productTypes = []
  vehicleTypes = []
  accounts = []

  inputName = null
  inputProductType = null
  inputVehicleType = null
  inputDomesticAccount = null
  inputInsideEuAccount = null
  inputOutsideEuAccount = null

  // i18n is not initialized here, so add rules at created function
  rules = {
    name: [],
    productType: [],
    account: [],
  }

  created() {
    if (this.$route.params.id !== undefined) {
      this.getEditData()
      this.pageMode = 'edit'
    }

    this.rules = {
      name: [(v) => !!v || this.$t('c:validation:This field is required')],
      productType: [(v) => !!v || this.$t('c:validation:This field is required')],
      account: [(v) => !!v || this.$t('c:validation:This field is required')],
    }

    this.$axios.get('/v3/economy/sales-accounting-rules/create/get_initial_data').then((response) => {
      this.productTypes = response.data.data.productTypes
      this.vehicleTypes = response.data.data.vehicleTypes
      this.accounts = response.data.data.accounts
    })
  }

  getEditData() {
    this.$axios
      .get('/v3/economy/sales-accounting-rules/create/get_edit_data/' + this.$route.params.id)
      .then((response) => {
        this.inputName = response.data.data.accountingRule.name
        this.inputProductType = response.data.data.accountingRule.productTypeId
        this.inputVehicleType = response.data.data.accountingRule.vehicleTypeId
        this.inputDomesticAccount = response.data.data.accountingRule.domesticAccountId
        this.inputInsideEuAccount = response.data.data.accountingRule.insideEuAccountId
        this.inputOutsideEuAccount = response.data.data.accountingRule.outsideEuAccountId
        this.fallbackType = response.data.data.accountingRule.fallbackType
        if (this.pageMode === 'edit' && this.fallbackType !== null) {
          this.rules.productType = []
        }
      })
      .catch((err) => {
        err.response.data.errors.forEach((v, i) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
        this.$router.push({
          name: 'Settings/Economy/SalesAccountingRules/List',
        })
      })
  }

  save() {
    if (this.$refs.form.validate()) {
      this.isSaving = true

      const data = {
        id: this.$route.params.id,
        name: this.inputName,
        productType: this.inputProductType,
        vehicleType: this.inputVehicleType,
        domesticAccount: this.inputDomesticAccount,
        insideEuAccount: this.inputInsideEuAccount,
        outsideEuAccount: this.inputOutsideEuAccount,
      }
      this.$axios
        .post('/v3/economy/sales-accounting-rules/create/save', data)
        .then((response) => {
          vxm.alert.success({
            content: this.$t('c:common:Successfully saved') as string,
            title: this.$t('c:common:Success') as string,
          })
          this.$router.push({
            name: 'Settings/Economy/SalesAccountingRules/List',
          })
        })
        .catch((err) => {
          err.response.data.errors.forEach((v, i) => {
            vxm.alert.error({
              content: v.message as string,
              title: this.$t('c:common:Error') as string,
            })
          })
        })
        .finally(() => {
          this.isSaving = false
        })
    }
  }
}
